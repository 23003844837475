import React, { Fragment, useEffect, useState } from 'react';
import {
  func, shape, array,
} from 'prop-types';
import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { uploadAttachments, deleteAttachment, resetAttachmentErrors } from 'shared/modules/support/redux/actions';
import { ErrorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';
import Box from 'shared/styleguide/atoms/Box';

import InfoText from 'shared/styleguide/molecules/InfoText';
import UploadAttachmentButton from './UploadAttachmentButton';

import styles from '../../Support.scss';

const Attachment = withStyles({
  root: {
    marginBottom: 6,
    marginRight: 6,
    color: colors.navy20,
  },
})(Chip);

export const UploadAttachments = ({
  attachments, uploadAttachments, deleteAttachment, resetAttachmentErrors,
}) => {
  const [uploadStatus, setUploadStatus] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (attachments.errAllMessages.length > 0) {
      setErrors((prev) => [...prev, ...attachments.errAllMessages]);
    }
  }, [attachments.errAllMessages]);

  const clearInput = () => {
    if (document.getElementById('upload')) {
      document.getElementById('upload').value = '';
    }
  };

  const handleAddFiles = async (e) => {
    let files = Array.from(e.target.files);
    setUploadStatus('doing');
    setErrors([]);
    resetAttachmentErrors();
    let alreadyUploaded = [];

    // filter files first
    files = files.filter((file) => {
      if (!file.type) {
        setErrors((prev) => [...prev, { file: file.name, messages: 'File must have a supported file type' }]);
      }

      return Boolean(file.type);
    });

    // loop over all selected files, but skip any that are already there
    // keep progress animation until all are processed
    const promises = files.map(async (file) => {
      alreadyUploaded = attachments.data.filter((r) => r.file_name === file.name);
      if (alreadyUploaded.length > 0) {
        setErrors((prev) => [...prev, { file: file.name, messages: 'File already uploaded.' }]);
      } else {
        return uploadAttachments(file);
      }
    });

    const results = await Promise.all(promises);
    setUploadStatus(files.length === results.length ? 'success' : '');
    clearInput();
  };

  const handleDeleteAttachment = (id) => {
    deleteAttachment(id);
    clearInput();
  };

  const errorMessages = () => errors.map((item) => {
    return <ErrorText key={item.file}>{`${item.messages?.description || item.messages} [${item.file}]`}</ErrorText>;
  });

  return (
    <Fragment>
      {attachments?.data?.length > 0
        && (
          <div>
            <Box id="attachmentsHeader" row align="center" padding={{ top: 'small', bottom: 'xsmall' }}>
              <AttachmentIcon />
              <Typography variant="h5">Attachments:</Typography>
            </Box>
            <Box id="attachmentsContainer" row margin={{ bottom: 'small' }} wrap="wrap">
              {attachments.data.map(
                (attachment) => (
                  <Attachment
                    key={attachment.id}
                    label={attachment.file_name}
                    onClick={() => window.open(attachment.content_url, '_blank', 'noopener')}
                    onDelete={(() => handleDeleteAttachment(attachment.id))}
                    variant="outlined"
                    className={styles.attachment}
                  />
                ),
              )}
            </Box>
          </div>
        )}
      <UploadAttachmentButton
        uploadStatus={uploadStatus}
        onChange={handleAddFiles}
        errors={errorMessages().length > 0}
      />
      {errorMessages().length > 0
        && (
          <Box margin={{ top: 'small' }}>
            {errorMessages()}
          </Box>
        )}
      <div style={{ marginTop: 10 }}>
        <InfoText>Maximum attachment file size: 20MB</InfoText>
      </div>
    </Fragment>
  );
};

UploadAttachments.propTypes = {
  attachments: shape({
    data: array,
    errAllMessages: array,
  }),
  deleteAttachment: func,
  resetAttachmentErrors: func,
  uploadAttachments: func,
};

export default connect(
  (state) => ({
    attachments: state.support.attachments,
  }),
  {
    uploadAttachments, deleteAttachment, resetAttachmentErrors,
  },
)(UploadAttachments);
